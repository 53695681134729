import * as React from 'react';
import Layout from '../components/layout';
import Home from '../components/home';

import { startBasicScene } from '../basic_scene';

const IndexPage = () => {
  
  React.useEffect(() => {
    if (!document.querySelector('.background-animation'))
      startBasicScene()
  })

  return (
    <Layout pageTitle="Home Page">
      <Home />
    </Layout>
  );
}

export default IndexPage;

