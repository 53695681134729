import * as React from "react";
import { Link } from "gatsby";
import "./home.css";

const Home = () => {
  return (
    <div className="home">
      <div className="link">
        <Link to={`/`}>
        <div className="matte">
          <h1 className="banner">Welcome</h1>
          </div>
        </Link>
      </div>
      <div className="link">
        <Link to={`/software-engineering`}>
          <div className="matte">
            <h2>Software Engineering</h2>
          </div>
        </Link>
      </div>
      <div className="link">
        <Link to={`/web-development`}>
        <div className="matte">
          <h2>Web Development</h2>
        </div>
        </Link>
      </div>
      <div className="link">
        <Link to={`/misc`}>
        <div className="matte">
          <h2>Miscellanea</h2>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Home;
